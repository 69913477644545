import React from "react";
import withSVG from "./withSVG";

const ExtendIcon = () => (
  <g stroke="#fff" strokeLinecap="round" strokeLinejoin="round">
    <path d="M17 1.35L30 1v14M30 1L17 15M13 31.65L0 32V18M0 32l13-14M0 8V1h7M23 32h7v-7" />
  </g>
);

export default withSVG(ExtendIcon, {
  viewBox: "0 0 31 33",
  width: "31",
  height: "33",
});
