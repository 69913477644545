import React from 'react';
import withSVG from './withSVG';

const Time = () => (
  <>
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="0.5"
      d="M13.499 6.874a6.625 6.625 0 11-13.25 0 6.625 6.625 0 0113.25 0z"
      clipRule="evenodd"
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="0.5"
      d="M6.875 4.02v2.853H2.75"
    />
  </>
);

export default withSVG(Time, {
  viewBox: '0 0 14 14',
  width: '14',
  height: '14',
});
