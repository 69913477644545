import { supportedPDFFormats, maxPDFFileSize } from '../config';

export function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function validatePhone(phone) {
  const regex = /^[0-9 ()+-]+$/;

  return regex.test(phone);
}

export function isRequired(formData) {
  const values = Object.values(formData);

  for (let i = 0; i < values.length; i += 1) {
    if (!values[i]) return false;
  }

  return true;
}

export const validatePDFFile = file => {
  const fileExtension = file.name.split('.').pop().toLowerCase();
  if (!supportedPDFFormats.includes(fileExtension)) {
    return `Please upload only PDF file`;
  }

  if (file.size > maxPDFFileSize) {
    return `Maximum PDF file size is ${maxPDFFileSize / 1024 / 1024}MB`;
  }

  return '';
};
