import React from 'react';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import styled from 'styled-components';
import { media } from '../styles';
import { CloseIcon } from '../icons';

const StyledOverlay = styled(DialogPrimitive.Overlay)`
  background-color: #020a1499;
  position: fixed;
  z-index: 1000;
  inset: 0;
`;

const StyledContent = styled(DialogPrimitive.Content)`
  background: #071526;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 800px;
  max-width: 100%;
  max-height: 85vh;
  padding: 60px 20px;
  z-index: 2000;

  &:focus: {
    outline: 'none';
  }

  ${media.md`
      padding: 60px;
    `}
`;

function Content({ children, ...props }) {
  return (
    <DialogPrimitive.Portal>
      <StyledOverlay />
      <StyledContent {...props}>{children}</StyledContent>
    </DialogPrimitive.Portal>
  );
}

const IconButton = styled.button`
  all: unset;
  font-family: inherit;
  border-radius: 100%;
  height: 25px;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

// Exports
export const Dialog = DialogPrimitive.Root;
export const DialogContent = Content;
export const DialogClose = DialogPrimitive.Close;

const DialogExtended = ({ children, open, handleClose }) => (
  <Dialog open={open}>
    <DialogContent>
      {children}
      <DialogClose asChild>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogClose>
    </DialogContent>
  </Dialog>
);

export default DialogExtended;
