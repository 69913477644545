import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { InputField, Box, Button, Typography } from '.';
import { validatePhone } from '../utils/validator';

export default function UpdateCandidateForm({
  candidateData = {},
  handleSubmitData,
}) {
  const { handleBlur, values, handleChange, errors, touched, handleSubmit } =
    useFormik({
      initialValues: {
        email: '',
        fristName: '',
        lastName: '',
        mobile: '',
        ...candidateData,
      },
      validationSchema: Yup.object({
        fristName: Yup.string().required('Please enter your first name'),
        lastName: Yup.string().required('Please enter your last name'),
        email: Yup.string()
          .email('Please enter valid email')
          .required('Please enter your email'),
        mobile: Yup.string()
          .test('format', 'Please enter valid mobile number', value =>
            validatePhone(value)
          )
          .required('Please enter your mobile'),
      }),
      onSubmit: data => {
        handleSubmitData(data);
      },
    });

  return (
    <form onSubmit={handleSubmit}>
      <Box>
        <InputField
          value={values.fristName}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="First Name"
          name="fristName"
        />
        {errors.fristName && touched.fristName && (
          <Typography color="error" my={2}>
            {errors.fristName}
          </Typography>
        )}
        <InputField
          value={values.lastName}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="Last Name"
          name="lastName"
        />
        {errors.lastName && touched.lastName && (
          <Typography color="error" my={2}>
            {errors.lastName}
          </Typography>
        )}
        <InputField
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.email}
          placeholder="Email"
          name="email"
        />
        {errors.email && touched.email && (
          <Typography color="error" my={2}>
            {errors.email}
          </Typography>
        )}
        <InputField
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.mobile}
          placeholder="Mobile"
          name="mobile"
        />
        {errors.mobile && touched.mobile && (
          <Typography color="error" my={2}>
            {errors.mobile}
          </Typography>
        )}
        <Button color="primary" variant="contained" mt={2} type="submit">
          Submit
        </Button>
      </Box>
    </form>
  );
}
