import React from 'react';
import * as ToastPrimitive from '@radix-ui/react-toast';
import styled from 'styled-components';
import { Typography } from '.';

const VIEWPORT_PADDING = '25px';

const StyledViewport = styled(ToastPrimitive.Viewport)`
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  padding: ${VIEWPORT_PADDING};
  gap: 10px;
  width: 390px;
  max-width: 100vw;
  margin: 0;
  list-style: none;
  z-index: 2147483647;
  outline: none;
`;

const StyledToast = styled(ToastPrimitive.Root)`
  background-color: var(--secondary-color);
  border-radius: 6px;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  padding: 15px;
  display: grid;
  grid-template-areas: 'title action' 'description action';
  grid-template-columns: auto max-content;
  column-gap: 15px;
  align-items: center;
`;

const StyledTitle = styled(ToastPrimitive.Title)`
  grid-area: title;
  margin-bottom: 5px;
  font-weight: 500;
  color: var(--text-primary-color);
  font-size: 15px;
`;

const StyledDescription = styled(ToastPrimitive.Description)`
  grid-area: description;
  margin: 0;
  color: var(--text-primary-color);
  font-size: 13px;
  line-height: 1.3;
`;

const StyledAction = styled(ToastPrimitive.Action)`
  grid-area: action;
`;

export const ToastProvider = ToastPrimitive.Provider;
export const ToastViewport = StyledViewport;
export const Toast = StyledToast;
export const ToastTitle = StyledTitle;
export const ToastDescription = StyledDescription;
export const ToastAction = StyledAction;
export const ToastClose = ToastPrimitive.Close;

const ToastExtended = ({ open, setOpen }) => (
  <ToastProvider swipeDirection="right">
    <Toast open={open} onOpenChange={setOpen} duration={3000}>
      <ToastTitle>
        <Typography variant="heading5" mb={1}>
          Success!
        </Typography>
      </ToastTitle>
      <ToastDescription asChild>
        <Typography variant="paragraph1" mb={1}>
          Thank you for your application.
        </Typography>
      </ToastDescription>
    </Toast>
    <ToastViewport />
  </ToastProvider>
);

export default ToastExtended;
