import React from 'react';
import styled, { css } from 'styled-components';
import { space, layout } from 'styled-system';
import { media } from '../styles';

const ButtonWrapper = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;

  .hover-bg {
    transition-timing-function: ease;
    transition-duration: 0.15s;
    transition-property: top, left, right, bottom;
    pointer-events: none;
    background-color: ${({ type }) =>
      type === 'icon' ? 'transparent' : '#ffffff'};
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  :hover .hover-bg {
    top: 6px;
    right: -6px;
    bottom: -6px;
    left: 6px;
    z-index: 2;
    border: ${({ variant }) =>
      variant === 'outlined' ? '1px solid #008fd4' : 'none'};
  }

  ${space};
  ${layout};
`;

const StyledButton = styled.button`
  font-family: var(--primary-font);
  color: var(--text-primary-color);
  font-weight: var(--bold-weight);
  position: relative;
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  box-sizing: border-box;
  text-transform: capitalize;
  border-radius: 0;
  z-index: 20;
  outline: none;
  border: none;
  background: transparent;
  padding: ${({ type }) => (type === 'icon' ? '8px 10px' : '8px 24px 6px')};
  cursor: pointer;

  :disabled {
    color: rgba(0, 0, 0, 0.26);
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.12);
    pointer-events: none;
  }

  ${media.md`
      font-size: 14px;
      line-height: 23px;
    `}

  ${({ variant }) =>
    variant === 'contained' &&
    css`
      background: ${({ color }) =>
        color === 'primary'
          ? 'var(--primary-color)'
          : 'var(--secondary-color)'};
    `}
    
  ${({ variant }) =>
    variant === 'outlined' &&
    css`
      border: 1px solid;
      border-color: ${({ color }) =>
        color === 'primary'
          ? 'var(--primary-color)'
          : 'var(--secondary-color)'};
      color: ${({ color }) =>
        color === 'primary'
          ? 'var(--primary-color)'
          : 'var(--secondary-color)'};
    `}

  ${({ size }) =>
    size === 'small' &&
    css`
      height: 38px;
      font-size: 14px;
      padding: ${({ variant }) =>
        variant === 'icon' ? '8px 10px' : '4px 18px 3px'};
    `}
`;

const Button = ({
  variant,
  size,
  color = 'primary',
  disabled,
  onClick,
  children,
  href,
  type,
  ...rest
}) => (
  <ButtonWrapper variant={variant} type={type} {...rest}>
    <StyledButton
      disabled={disabled}
      onClick={onClick}
      size={size}
      type={type}
      color={color}
      variant={variant}
      as={href ? 'a' : 'button'}
    >
      {children}
    </StyledButton>
    <div className="hover-bg" />
  </ButtonWrapper>
);

export default Button;
