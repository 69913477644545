import React from 'react';
import { Typography, Grid, Container, Button, Box, Nav, Image } from '.';
import { socials, footerMenus1, footerMenus2, footerMenus3 } from '../config';

export default function Footer() {
  return (
    <Box
      pt={8}
      pb={{ _: 2, md: 4 }}
      mt={8}
      borderTop="1px solid #152539"
      px={{ _: 2, md: 0 }}
    >
      <Container>
        <Grid gridTemplateColumns={['1fr', '4fr 3fr']} gap={4}>
          <Box order={[2, 1]} textAlign={['center', 'left']} pb={[2, 0]}>
            <Box display="flex" justifyContent={['center', 'flex-start']}>
              <Image src="/images/logo.svg" alt="Spring Rain Private Ltd" />
            </Box>
            <Typography
              variant="body2"
              fontFamily="'Source Code Pro', monospace;"
              mt={2}
            >
              7th Floor, Commercial Cove, House 56/C , Road 132, Gulshan-1,
              Dhaka 1212, Bangladesh.
            </Typography>
            <Box mt={2} mb={{ _: 4, md: 2 }}>
              {socials.map((social, index) => (
                <a
                  type="icon"
                  target="_blank"
                  href={social.link}
                  style={{ marginRight: '24px' }}
                  key={index}
                  rel="noreferrer"
                >
                  {social.icon}
                </a>
              ))}
            </Box>
            <Typography
              fontSize="13px"
              fontFamily="'Source Code Pro', monospace;"
            >
              © {new Date().getFullYear()} Spring Rain Private Ltd
            </Typography>
          </Box>
          <Grid
            order={[1, 2]}
            gridTemplateColumns={['1fr', '1fr 1fr 1fr']}
            gridGap={[0, 4]}
            justifyItems={['center', 'left']}
          >
            <Nav list menus={footerMenus1} />
            <Nav list menus={footerMenus2} />
            <Nav list menus={footerMenus3} />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
