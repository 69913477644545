import Axios from 'axios';
import { useQuery, useMutation } from 'react-query';
import { apiEndpoint } from '../config';

const axios = Axios.create({
  baseURL: apiEndpoint,
});

export const useGetJobs = () =>
  useQuery(
    'jobs',
    async () => {
      const { data } = await axios.get(`jobs`);

      return data;
    },
    {
      staleTime: 5 * 60 * 1000,
      cacheTime: 15 * 60 * 1000,
    }
  );

export const useGetJob = id =>
  useQuery(
    ['jobs', id],
    async () => {
      const { data } = await axios.get(`jobs/${id}`);

      return data;
    },
    {
      staleTime: 5 * 60 * 1000,
      cacheTime: 15 * 60 * 1000,
      retry: false,
    }
  );

export const useUploadResume = () =>
  useMutation(({ data }) => axios.post(`/upload/resume`, data));

export const useCandidateAssociate = () =>
  useMutation(({ data }) => axios.post(`/candidates/associate`, data));

export const useUpdateCandidate = () =>
  useMutation(({ data }) => axios.put(`/candidates`, data));
