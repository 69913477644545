import React from "react";
import styled, { css } from "styled-components";
import { CloseIcon } from "../icons";
import { Box } from "./";

const DrawerWrapper = styled.div`
  position: fixed;
  background: var(--bg-color);
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  padding: 0 24px;
  z-index: var(--high-index);
  box-shadow: rgb(67 71 85 / 27%) 0 0 0.25em, rgb(90 125 188 / 5%) 0 0.25em 1em;
  transform: translateX(-100vw);
  transition: transform 0.5s cubic-bezier(0.22, 1, 0.36, 1);
  opacity: 0;
  padding: 24px 16px;

  ${({ open }) =>
    open &&
    css`
      transform: translateX(0);
      opacity: 1;
    `}
`;

export default function Drawer({ open, onClose, children }) {
  return (
    <DrawerWrapper open={open}>
      <Box display="flex" justifyContent="flex-end">
        <CloseIcon onClick={onClose} />
      </Box>
      {children}
    </DrawerWrapper>
  );
}
