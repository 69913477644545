import React from 'react';
import styled from 'styled-components';
import { space } from 'styled-system';
import media from '../styles/mediaQuery';

const RawHTMLContainer = styled.div`
  font-size: 14px;
  line-height: 32px;

  h1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    letter-space: -0.01562em;
    margin-bottom: 24px;
    margin-top: 40px;

    ${media.md`
      font-size: 36px;
      line-height: 45px;
    `}
  }

  h2 {
    font-weight: 700;
    font-size: 22px;
    line-height: 36px;
    letter-space: --0.00833em;
    margin-bottom: 24px;
    margin-top: 32px;

    ${media.md`
      font-size: 30px;
      line-height: 40px;
      margin-top: 40px;
    `}
  }

  h3 {
    font-weight: 700;
    font-size: 22px;
    line-height: 32px;
    letter-space: --0.00833em;
    margin-bottom: 24px;
    margin-top: 32px;

    ${media.md`
      font-size: 24px;
      line-height: 36px;
      margin-top: 40px;
    `}
  }

  h4,
  h5,
  h6 {
    font-weight: 700;
    font-size: 16px;
    line-height: 32px;
    letter-space: --0.00735em;
    margin-bottom: 24px;
    margin-top: 32px;

    ${media.md`
      font-size: 20px;
      line-height: 30px;
      margin-top: 40px;
    `}
  }

  p {
    font-weight: 400;
    margin-bottom: 4px;
    font-size: 14px;
    line-height: 32px;
  }

  a {
    text-decoration: underline;
    color: #fff;
  }

  blockquote {
    border-left: 5px solid #fbb27e;
    margin: 20px 0;
    padding: 20px;
  }

  blockquote:before {
    content: open-quote;
    color: #ccc;
    font-size: 14px;
    line-height: 0.1em;
    margin-right: 0.25em;
    vertical-align: -0.4em;
  }

  blockquote p {
    display: inline;
  }

  img {
    width: 100%;
    height: auto;
    margin: 32px 0;
    border-radius: 4px;
  }

  ul,
  ol {
    padding-left: 40px;
  }

  ol.nestedList {
    counter-reset: item;
  }
  ol.nestedList > li {
    display: block;
  }

  ol.nestedList > li:before {
    content: counters(item, '.') '. ';
    counter-increment: item;
  }

  ol.nestedList > li > ol > li:before {
    content: counters(item, '.') '. ';
    counter-increment: item;
  }

  ol.nestedList > li > ol > li > ol > li:before {
    content: none;
    counter-increment: item;
  }

  ${space};
`;

const RawHTML = ({ html, ...props }) => (
  <RawHTMLContainer dangerouslySetInnerHTML={{ __html: html }} {...props} />
);

export default RawHTML;
