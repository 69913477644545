import React from "react";
import withSVG from "./withSVG";

const Facebook = () => (
  <path
    fill="#fff"
    fillRule="evenodd"
    d="M.5 14h2c.276 0 .5-.207.5-.461V5.46C3 5.207 2.776 5 2.5 5h-2c-.276 0-.5.207-.5.461v8.078c0 .254.224.461.5.461zM.5 3h2c.276 0 .5-.242.5-.54V.54C3 .242 2.776 0 2.5 0h-2C.224 0 0 .242 0 .54v1.92c0 .298.224.54.5.54zM15.199 5.27c-.3-.37-.74-.674-1.324-.912A5.054 5.054 0 0011.943 4c-1.43 0-2.642.512-3.636 1.538-.197.205-.34.16-.34-.116v-.667c0-.275-.239-.499-.533-.499H5.532c-.294 0-.532.224-.532.5V13.5c0 .275.238.499.532.499h2.13c.293 0 .531-.224.531-.5v-3.04c0-1.268.082-2.137.245-2.606.162-.47.463-.847.903-1.132.44-.284.936-.427 1.489-.427.431 0 .8.1 1.108.3.307.199.528.478.665.838.136.36.204 1.151.204 2.375V13.5c0 .275.238.499.532.499h2.129c.294 0 .532-.224.532-.5V8.549c0-.875-.059-1.548-.176-2.018a3.22 3.22 0 00-.625-1.26z"
    clipRule="evenodd"
  ></path>
);

export default withSVG(Facebook, {
  viewBox: "0 0 16 14",
  width: "16",
  height: "14",
});
