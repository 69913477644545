import React, { useState, useRef, useEffect } from 'react';
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-v3';
import styled from 'styled-components';
import { Typography, Box, Button, InputField, Grid, Loader } from '.';
import { apiEndpoint, reCaptchaSiteKey } from '../config';
import { isRequired, validateEmail } from '../utils/validator';

const ReCaptchaNotice = styled.div`
  color: var(--text-secondary-color);

  a {
    margin-left: 4px;
    color: var(--text-secondary-color);
    text-decoration: underline;
  }
`;

const formDefaultState = {
  email: '',
};

const getFormattedMessage = ({ email }) => {
  const body = `
  <p>Please delete the account associated with the following email address</p>
  <p>Email: ${email} </p>
  
  </br>
  
  <p>Thanks</p>
  <p>Team SRIT</p>
`;

  return body;
};

export default function DeleteRequestForm({ showSidebar = true }) {
  const [submitting, setSubmitting] = useState(false);
  const [form, setForm] = useState(formDefaultState);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [token, setToken] = useState('');
  const reCaptchaRef = useRef(null);

  useEffect(() => {
    loadReCaptcha(reCaptchaSiteKey);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendData = () => {
    const data = {
      token,
      subject: 'Delete account from QUIZZY App',
      message: getFormattedMessage(form),
      email: form.email,
    };

    fetch(`${apiEndpoint}/email/send`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(res => res.json())
      .then(response => {
        if (response && response.status === 'sent') {
          setForm(formDefaultState);
          setSuccess(
            'Your request has been accepted. We will remove your account within 2-3 days.'
          );
          setSubmitting(false);
        } else {
          setError(
            'Sorry, we have encountered an error. Please call this number +88 029 860116'
          );
          setSubmitting(false);
        }
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.error('Error:', err);
        setError(
          'Sorry, we have encountered an error. Please call this number +88 029 860116'
        );
        setSubmitting(false);
      });
  };

  useEffect(() => {
    if (submitting) {
      sendData();
    }
  }, [token]);

  const handleSubmit = async () => {
    const isValid = isRequired(form) && validateEmail(form.email);

    if (!isValid) {
      setError(
        'One of the required field is empty or contains invalid data; please check your input.'
      );
      return;
    }

    setSubmitting(true);
    reCaptchaRef.current.execute();
  };

  const handleTextChange = (name, event) => {
    if (error) {
      setError('');
    }

    if (success) {
      setSuccess('');
    }

    setForm({
      ...form,
      [name]: event.target.value,
    });
  };

  const verifyCallback = recaptchaToken => {
    setToken(recaptchaToken);
  };

  return (
    <>
      <Typography variant="heading4" mb={{ _: 12, md: 12 }}>
        Account Deletion Request
      </Typography>

      <Box>
        <Grid
          gridTemplateColumns={{
            _: '1fr',
            sm: `${showSidebar ? '2fr 1fr' : '1fr'}`,
          }}
        >
          <Loader loading={submitting} opacity="0.3">
            <Box border="1px solid #152539" p={{ _: 3, md: 6 }}>
              <Grid gridGap={3} gridTemplateColumns={{ _: '1fr', sm: '1fr' }}>
                <Box>
                  <InputField
                    onChange={event => handleTextChange('email', event)}
                    placeholder="Email"
                    value={form.email}
                  />
                </Box>
              </Grid>

              {error && (
                <Typography variant="paragraph2" color="error" mt={2}>
                  {error}
                </Typography>
              )}

              {success && (
                <Typography variant="paragraph1" my={2}>
                  {success}
                </Typography>
              )}

              <ReCaptcha
                ref={reCaptchaRef}
                sitekey={reCaptchaSiteKey}
                action="contact_us_submit"
                verifyCallback={verifyCallback}
              />

              <ReCaptchaNotice>
                This site is protected by reCAPTCHA and the Google
                <a href="https://policies.google.com/privacy">
                  Privacy Policy
                </a>{' '}
                and
                <a href="https://policies.google.com/terms">
                  Terms of Service
                </a>{' '}
                apply.
              </ReCaptchaNotice>

              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={submitting}
                mt={2}
              >
                Submit Request
              </Button>
            </Box>
          </Loader>
        </Grid>
      </Box>
    </>
  );
}
