import React from "react";
import withSVG from "./withSVG";

const ServiceIcon = () => (
  <g>
    <path
      clipRule="evenodd"
      d="M0 27.573L2.528 25 9 31.385 6.474 34 0 27.573zm5-3.403L9.678 29s1.098-.965 1.802-1.056c.702-.09 5.49.135 8.434-.538 2.942-.674 9.883-2.833 10.762-4.046.878-1.213-.175-2.696-1.406-2.248-1.23.45-6.897 2.428-9.312 2.473-2.416.046-4.218.495-4.526-.359-.308-.854.44-1.215 1.186-1.215.748 0 2.372.405 2.812-.63.44-1.031.22-2.426-1.185-2.38-1.407.045-4.525.225-5.668.225-1.142 0-4.11 1.06-7.577 4.944z"
      stroke="#fff"
      strokeWidth={0.75}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.5 11.31a2.811 2.811 0 01-2.81-2.81h-.75a3.561 3.561 0 003.56 3.56v-.75zM15.69 8.5a2.81 2.81 0 012.81-2.811v-.75a3.56 3.56 0 00-3.56 3.56h.75zm2.81-2.811a2.81 2.81 0 012.811 2.81h.75a3.56 3.56 0 00-3.56-3.56v.75zm2.811 2.81a2.811 2.811 0 01-2.81 2.81v.75a3.561 3.561 0 003.56-3.56h-.75zm-2.81-6.874a6.875 6.875 0 00-6.876 6.874h.75a6.125 6.125 0 016.126-6.124v-.75zm-6.876 6.874a6.876 6.876 0 006.876 6.876v-.75a6.126 6.126 0 01-6.126-6.126h-.75zm6.876 6.876a6.875 6.875 0 006.874-6.876h-.75a6.125 6.125 0 01-6.124 6.126v.75zm6.874-6.876a6.874 6.874 0 00-6.874-6.874v.75a6.124 6.124 0 016.124 6.124h.75z"
      fill="#fff"
    />
    <path
      d="M16 1.944V.35c0-.194.202-.35.45-.35h3.098c.25 0 .452.156.452.35V2m0 13v1.643c0 .197-.202.357-.45.357h-3.098c-.25 0-.452-.16-.452-.357v-1.585M12.395 5L11.12 3.726a.415.415 0 01-.001-.585l1.99-2.019a.41.41 0 01.582-.002L15 2.427M23.497 12l1.382 1.359c.16.155.162.41.003.567l-1.963 1.955a.41.41 0 01-.575.003L21 14.564M11.988 10h-1.61C10.17 10 10 9.798 10 9.55v-3.1c0-.248.17-.45.378-.45H12m13 0h1.659c.188 0 .341.202.341.45v3.098c0 .25-.153.452-.341.452h-1.65M15 14.578l-1.275 1.298a.41.41 0 01-.584.004l-2.018-2.019a.42.42 0 01-.003-.59L12.37 12M21 2.472l1.348-1.353a.403.403 0 01.568-.003l1.964 1.936c.159.156.16.41.003.567L23.508 5"
      stroke="#fff"
      strokeWidth={0.75}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </g>
);

export default withSVG(ServiceIcon, {
  viewBox: "0 0 32 35",
  width: "32",
  height: "35",
});
