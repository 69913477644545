import styled from 'styled-components';
import { space } from 'styled-system';
import React from 'react';

const ArticleWrapper = styled.div`
  .prose > div > * {
    max-width: 700px;
    margin: 0 auto;
  }

  .prose > div > img {
    max-width: 900px;
    margin: 32px auto;
  }

  .prose > div > {
    h1,
    h2 {
      margin-top: 48px;
      margin-bottom: 16px;
    }

    h3,
    h4,
    h5,
    h6 {
      margin-top: 32px;
      margin-bottom: 16px;
      font-weight: 500;
    }
  }

  p {
    font-size: 16px;
  }

  ${space}
`;

export default function Article({ children }) {
  return (
    <ArticleWrapper>
      <article className="prose">{children}</article>
    </ArticleWrapper>
  );
}
