import React from 'react';
import { motion, useScroll } from 'framer-motion';
import styled from 'styled-components';

const ProgressBarStyled = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 5px;
  width: 100%;
  background: #f66d1d;
  transform-origin: 0%;
  z-index: 9999;
`;

const ProgressBar = () => {
  const { scrollYProgress } = useScroll();

  return <ProgressBarStyled style={{ scaleX: scrollYProgress }} />;
};

export default ProgressBar;
