import styled from "styled-components";
import {
  color,
  space,
  layout,
  border,
  display,
  flexbox,
  typography,
  position,
} from "styled-system";
import media from "../styles/mediaQuery";

const Section = styled.div`
  margin-top: 60px;
  padding: 16px;

  ${media.md`
    margin-top: 100px;
    padding: 0;
  `}

  ${color};
  ${space};
  ${layout};
  ${border};
  ${display};
  ${flexbox};
  ${position};
  ${typography};
`;

export default Section;
