import React from "react";
import withSVG from "./withSVG";

const BuildIcon = () => (
  <g stroke="#fff" strokeLinecap="round" strokeLinejoin="round">
    <path d="M0 17v-2.664l4.723-1.142.591-2.03L3.19 6.852l3.306-3.806 4.014 2.41 1.77-.508L13.462 0h5.196l1.417 4.947 1.418.635 4.132-2.537 3.66 3.933-2.243 3.932.354 2.03L32 14.336V17" />
    <path d="M12 17c0-3.313 2.239-6 5-6s5 2.687 5 6" />
    <path
      clipRule="evenodd"
      d="M25.175 22.557C25.817 23.993 27.32 25 29.076 25c1.68 0 3.131-.925 3.814-2.265l-3.968.03V19.5H33c-.628-1.466-2.148-2.5-3.924-2.5-1.848 0-3.417 1.117-3.997 2.676l-16.153.015C8.352 18.125 6.777 17 4.925 17 3.149 17 1.628 18.034 1 19.5h4.078v3.265l-3.968-.03C1.793 24.075 3.245 25 4.925 25c1.866 0 3.45-1.14 4.013-2.723l16.237.28z"
    />
  </g>
);

export default withSVG(BuildIcon, {
  viewBox: "0 0 34 26",
  width: "34",
  height: "26",
});
