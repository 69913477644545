import React from 'react';
import withSVG from './withSVG';

const Logo = () => (
  <g fill="#1e7e7a">
    <path
      d="M214.3,99.5s-9.5,39.5,39,28.5c48-10.88,34.79-84.91-46.86-56.45a22.81,22.81,0,0,0-5,2.49c-99.13,65.38,21,149.23,81.91,197.63,23,18.24,24.42,43.9,10.17,57.83-.12,1,44.68-23.36,11.83-69.17C292.21,242,264.52,224.66,222.8,194c-88-67-24-129,27-104.54C275.8,104.5,219.3,130.67,214.3,99.5Z"
      transform="translate(-125.77 -65.08)"
    />
    <path
      d="M201.5,102.5c-.06,4.44-56.67,30.86,100,131.83,0,0,26.13-16.9-7.5-40.29C261.09,171.15,201.37,141,201.5,102.5Z"
      transform="translate(-125.77 -65.08)"
    />
    <path
      d="M171.5,185.5S162,217,246.5,259,295,382,258,357c0,0,59.45-16.17,3.5-59C209.54,258.23,128.56,238,171.5,185.5Z"
      transform="translate(-125.77 -65.08)"
    />
    <path
      d="M201.5,269s-13.5,14.5,45,36.5S247.5,410,213,339c0,0,16,13.67,25.67,12s29.33-23-21-42.33C184,292,200.15,269,200.15,269"
      transform="translate(-125.77 -65.08)"
    />
    <path
      d="M319.32,211.33s-3.65,28.67-10,29c0,0,37,47,19.34,93.67s-106,80-142.34,2c0,0-32.33,23-12.33-7.67s-23.33-43.66-27.33-13c0,0-26-22.33,10.66-60.33,0,0-59,20-15.66,76,0,0,.33,14,14.33,12.33,0,0,.58,29.59,29.67,13.34,0,0,64.66,91.43,149.66,4.55C395.67,292.33,319.32,211.33,319.32,211.33ZM157.12,331.81c-3.87-1.49-5.39-6.89-3.39-12.07s6.74-8.16,10.61-6.67,5.38,6.9,3.39,12.07S161,333.3,157.12,331.81Z"
      transform="translate(-125.77 -65.08)"
    />
    <ellipse cx="45.6" cy="310.38" rx="6.88" ry="9.2" />
    <ellipse cx="63.21" cy="323.84" rx="7.3" ry="9.77" />
    <ellipse
      cx="165.04"
      cy="281.47"
      rx="11.66"
      ry="8.79"
      transform="translate(-257.38 352.24) rotate(-84.22)"
    />
  </g>
);

export default withSVG(Logo, {
  viewBox: '0 0 199 35',
  width: '199',
  height: '35',
});
