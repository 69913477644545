import styled from 'styled-components';
import {
  grid,
  flexbox,
  space,
  border,
  layout,
  color,
  position,
  typography,
} from 'styled-system';

const Box = styled.div`
  @media screen and (max-width: 768px) {
    order: ${props => (props.flip ? 2 : 'unset')};
  }

  ${layout}
  ${space}
  ${border}
  ${color}
  ${grid}
  ${flexbox}
  ${position}
  ${typography}
`;

export default Box;
