import React, { useState, useRef, useEffect } from 'react';
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-v3';
import styled from 'styled-components';
import {
  Typography,
  Box,
  DetailsLink,
  Button,
  InputField,
  Grid,
  Loader,
} from '.';
import { apiEndpoint, reCaptchaSiteKey } from '../config';
import { isRequired, validateEmail, validatePhone } from '../utils/validator';

const ReCaptchaNotice = styled.div`
  color: var(--text-secondary-color);

  a {
    margin-left: 4px;
    color: var(--text-secondary-color);
    text-decoration: underline;
  }
`;

const formDefaultState = {
  name: '',
  email: '',
  phone: '',
  message: '',
};

const getFormattedMessage = ({ name, email, phone, message }) => {
  const body = `
  <p>Name: ${name} </p>
  <p>Email: ${email} </p>
  <p>Phone: ${phone} </p>
  <p>Message: ${message} </p>
  </br>
  
  <p>Thanks</p>
  <p>Team SRIT</p>
`;

  return body;
};

export default function ContactForm({ showSidebar = true }) {
  const [submitting, setSubmitting] = useState(false);
  const [form, setForm] = useState(formDefaultState);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [token, setToken] = useState('');
  const reCaptchaRef = useRef(null);

  useEffect(() => {
    loadReCaptcha(reCaptchaSiteKey);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendData = () => {
    const data = {
      token,
      subject: 'Contact Us Email',
      message: getFormattedMessage(form),
      email: form.email,
    };

    fetch(`${apiEndpoint}/email/send`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(res => res.json())
      .then(response => {
        if (response && response.status === 'sent') {
          setForm(formDefaultState);
          setSuccess('Thank you for contacting us.');
          setSubmitting(false);
        } else {
          setError(
            'Sorry, we have encountered an error. Please call this number +88 029 860116'
          );
          setSubmitting(false);
        }
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.error('Error:', err);
        setError(
          'Sorry, we have encountered an error. Please call this number +88 029 860116'
        );
        setSubmitting(false);
      });
  };

  useEffect(() => {
    if (submitting) {
      sendData();
    }
  }, [token]);

  const handleSubmit = async () => {
    const isValid =
      isRequired(form) &&
      validateEmail(form.email) &&
      validatePhone(form.phone);

    if (!isValid) {
      setError(
        'One of the required field is empty or contains invalid data; please check your input.'
      );
      return;
    }

    setSubmitting(true);
    reCaptchaRef.current.execute();
  };

  const handleTextChange = (name, event) => {
    if (error) {
      setError('');
    }

    if (success) {
      setSuccess('');
    }

    setForm({
      ...form,
      [name]: event.target.value,
    });
  };

  const verifyCallback = recaptchaToken => {
    setToken(recaptchaToken);
  };

  return (
    <>
      <Typography variant="heading4" mb={{ _: 4, md: 6 }}>
        Let’s talk.{' '}
        <span style={{ color: '#69798C' }}>
          Fill in your details and we’ll get back to you shortly.
        </span>
      </Typography>

      <Box>
        <Grid
          gridTemplateColumns={{
            _: '1fr',
            sm: `${showSidebar ? '2fr 1fr' : '1fr'}`,
          }}
        >
          <Loader loading={submitting} opacity="0.3">
            <Box border="1px solid #152539" p={{ _: 3, md: 6 }}>
              <Grid
                gridGap={3}
                gridTemplateColumns={{ _: '1fr', sm: '1fr 1fr' }}
              >
                <Box>
                  <InputField
                    onChange={event => handleTextChange('name', event)}
                    placeholder="Name"
                    value={form.name}
                  />
                  <InputField
                    onChange={event => handleTextChange('email', event)}
                    placeholder="Email"
                    value={form.email}
                  />
                  <InputField
                    onChange={event => handleTextChange('phone', event)}
                    placeholder="Phone"
                    value={form.phone}
                  />
                </Box>
                <Box>
                  <InputField
                    onChange={event => handleTextChange('message', event)}
                    placeholder="Message"
                    as="textarea"
                    rows={8}
                    value={form.message}
                  />
                </Box>
              </Grid>

              {error && (
                <Typography variant="paragraph2" color="error" mt={2}>
                  {error}
                </Typography>
              )}

              {success && (
                <Typography variant="paragraph1" my={2}>
                  {success}
                </Typography>
              )}

              <ReCaptcha
                ref={reCaptchaRef}
                sitekey={reCaptchaSiteKey}
                action="contact_us_submit"
                verifyCallback={verifyCallback}
              />

              <ReCaptchaNotice>
                This site is protected by reCAPTCHA and the Google
                <a href="https://policies.google.com/privacy">
                  Privacy Policy
                </a>{' '}
                and
                <a href="https://policies.google.com/terms">
                  Terms of Service
                </a>{' '}
                apply.
              </ReCaptchaNotice>

              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={submitting}
                mt={2}
              >
                Send Message
              </Button>
            </Box>
          </Loader>
          {showSidebar && (
            <Box display={{ _: 'none', sm: 'block' }}>
              <Box>
                <Typography variant="heading4" mb={2}>
                  Come Join Us
                </Typography>
                <Typography variant="paragraph2" mb={1}>
                  Do you have a passion for engineering? We’ve been looking for
                  you.
                </Typography>
                <DetailsLink to="/careers/">
                  See available opportunities
                </DetailsLink>
              </Box>
              <Box mt={6}>
                <Typography variant="heading4" mb={2}>
                  General Queries
                </Typography>
                <Typography variant="paragraph2" mb={1}>
                  We are here to answer all your queries. Feel free to ask
                  anything info@springrain.io
                </Typography>
                <DetailsLink to="/contact/">Email Us</DetailsLink>
              </Box>
            </Box>
          )}
        </Grid>
      </Box>
    </>
  );
}
