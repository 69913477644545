import React from "react";
import withSVG from "./withSVG";

const MenuIcon = () => (
  <g>
    <rect width="20" height="3" x="11" fill="#fff" rx="1.5"></rect>
    <rect width="31" height="3" y="8" fill="#fff" rx="1.5"></rect>
    <rect width="20" height="3" x="11" y="16" fill="#fff" rx="1.5"></rect>
  </g>
);

export default withSVG(MenuIcon, {
  viewBox: "0 0 31 19",
});
