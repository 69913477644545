import React from 'react';
import styled, { css } from 'styled-components';
import { space } from 'styled-system';
import { Link, Typography } from '.';
import { RightArrowIcon } from '../icons';
import media from '../styles/mediaQuery';

const Box = styled(Link)`
  display: flex;

  ${({ variant }) =>
    variant === 'underline'
      ? css`
          font-size: 14px;
          line-height: 24px;
          font-weight: 500;

          ${media.md`
            font-size: 20px;
            line-height: 30px;
          `}

          .text {
            background-image: linear-gradient(
              transparent calc(100% - 4px),
              #f66d1d 10px
            );
            background-repeat: no-repeat;
            background-size: 0% 100%;
            transition: background-size 1s;
          }

          :hover .text {
            background-size: 100% 100%;
          }
        `
      : css`
          display: flex;
          align-items: center;
        `}

  ${space};
`;

export default function DetailsLink({
  to,
  children,
  variant = 'arrow',
  textVariant = 'paragraph2',
  showStartArrow = false,
  ...rest
}) {
  const isLinkAvailable = Boolean(to);
  // return (
  //   <Box to={to} variant={variant} {...rest}>
  //     {showStartArrow && <RightArrowIcon mt="4px" mr={1} fill="#f66d1d" />}
  //     <Typography variant={textVariant} className="text">
  //       {children}
  //     </Typography>
  //     {variant === 'arrow' && <RightArrowIcon ml={1} fill="#188DCD" />}
  //   </Box>
  // );

  if (isLinkAvailable) {
    return (
      <Box to={to} variant={variant} {...rest}>
        {showStartArrow && <RightArrowIcon mt="4px" mr={1} fill="#f66d1d" />}
        <Typography variant={textVariant} className="text">
          {children}
        </Typography>
        {variant === 'arrow' && <RightArrowIcon ml={1} fill="#188DCD" />}
      </Box>
    );
  } else {
    return (
      <Box variant={variant} {...rest}>
        {showStartArrow && <RightArrowIcon mt="4px" mr={1} fill="#f66d1d" />}
        <Typography variant={textVariant} className="text">
          {children}
        </Typography>
        {variant === 'arrow' && <RightArrowIcon ml={1} fill="#188DCD" />}
      </Box>
    );
  }
}
