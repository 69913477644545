import React from 'react';
import { FacebookIcon, LinkedInIcon } from '../icons';

export const emailAddress = 'career@springrain.io';
export const apiEndpoint = process.env.GATSBY_API_ENDPOINT;
export const appENV = process.env.GATSBY_APP_ENV;

export const reCaptchaSiteKey = process.env.GATSBY_RE_CAPTCHA_SITE_KEY;
export const supportedPDFFormats = ['pdf'];
export const maxPDFFileSize = 10 * 1024 * 1024; // 10MB

export const menus = [
  {
    label: 'Home',
    url: '/',
  },
  {
    label: 'About',
    url: '/about/',
  },
  {
    label: 'Expertise',
    url: '/expertise/',
    submenu: [
      { label: 'Cloud', url: '/cloud-service' },
      { label: 'App Development', url: '/application-development' },
      { label: 'AI/ML', url: '/ai-ml-solution' },
      { label: 'Team augmentaion', url: '/team-augmentation' },
      { label: 'IT Services', url: '/managed-it-services' },
      { label: 'Blockchain and NFT', url: '/' },
    ],
  },
  {
    label: 'Case Studies',
    url: '/case-studies/',
  },
  // {
  //   label: 'Blogs',
  //   url: '/blogs/',
  // },
  {
    label: 'Career',
    url: '/careers/',
  },
];

export const footerMenus1 = [
  {
    label: 'Home',
    url: '/',
  },
  {
    label: 'Expertise',
    url: '/expertise/',
  },
  {
    label: 'Case Studies',
    url: '/case-studies/',
  },
  // {
  //   label: 'Blogs',
  //   url: '/blogs/',
  // },
  {
    label: 'Career',
    url: '/careers/',
  },
];

export const footerMenus2 = [
  {
    label: 'About',
    url: '/about/',
  },
  {
    label: 'Contact',
    url: '/contact/',
  },
];

export const footerMenus3 = [
  {
    label: 'Privacy Policy',
    url: '/privacy-policy/',
  },
];

export const socials = [
  {
    icon: <FacebookIcon width={16} height={16} fill="#fff" />,
    link: 'https://www.facebook.com/springrainpvtltd',
  },
  {
    icon: <LinkedInIcon />,
    link: 'https://www.linkedin.com/company/spring-rain/',
  },
];
