import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { Grid, Box, Button, Nav, Drawer, Image } from '.';
import { MenuIcon } from '../icons';
import { menus } from '../config';
import { useToggle } from '../hooks';

export default function Header() {
  const [openMobileMenu, setOpenMobileMenu] = useToggle(false);
  const [navBackground, setNavBackground] = useState('transparent');
  const isNavbarSticky = process.env.GATSBY_NAVBAR_STICKY;
  const handleScroll = () => {
    if (window.scrollY > 50) {
      const lightBg = getComputedStyle(document.documentElement)
        .getPropertyValue('--navbar-bg')
        .trim();
      setNavBackground(lightBg);
    } else {
      setNavBackground('transparent');
    }
  };

  useEffect(() => {
    if (isNavbarSticky) {
      window.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (isNavbarSticky) {
        window.removeEventListener('scroll', handleScroll);
      }
    };
  }, [isNavbarSticky]);

  return (
    <Grid
      position={isNavbarSticky ? 'sticky' : 'static'}
      top={0}
      opacity="1"
      zIndex="var(--high-index)"
      backgroundColor={navBackground}
      transition="background-color 0.3s ease"
    >
      <Grid
        gridTemplateColumns={{ _: '1fr 1fr', md: 'auto 1fr 1fr' }}
        alignItems="center"
        maxWidth="1600px"
        mx="auto"
        p={2}
        position="relative"
        zIndex="var(--mid-index)"
        width="100%"
      >
        <Box display={{ _: 'none', md: 'block' }} item md={6} lg={4}>
          <Nav menus={menus} />
        </Box>
        <Box>
          <Box textAlign={{ _: 'left', md: 'center' }}>
            <Link to="/">
              <Image
                width="200"
                height="35"
                src="/images/logo.svg"
                mx={{ _: 'left', md: 'auto' }}
                alt="Spring Rain Private Ltd"
              />
            </Link>
          </Box>
        </Box>
        <Box>
          <Box textAlign="right">
            <Box display={{ _: 'none', md: 'block' }}>
              <Link to="/contact/">
                <Button variant="contained" color="primary">
                  Get in Touch
                </Button>
              </Link>
            </Box>
            <Box display={{ _: 'block', md: 'none' }}>
              <Button type="icon" onClick={setOpenMobileMenu}>
                <MenuIcon fill="#F6931D" />
              </Button>
            </Box>
          </Box>
        </Box>
      </Grid>

      <Drawer open={openMobileMenu} onClose={setOpenMobileMenu}>
        <Nav itemspace={{ my: 4 }} alignItems="center" list menus={menus} />
      </Drawer>
    </Grid>
  );
}
