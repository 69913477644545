import React, { useState } from 'react';
import styled from 'styled-components';
import { space } from 'styled-system';
import { Link } from 'gatsby';
import { media } from '../styles';
import { motion } from 'framer-motion';

const Menu = styled.ul`
  display: flex;
  align-items: ${({ isList, alignItems }) => (isList ? 'center' : alignItems)};
  flex-direction: ${({ isList }) => (isList ? 'column' : 'row')};
  justify-content: ${({ align, isList }) =>
    isList ? 'center' : align || 'flex-start'};
  margin: 0;
  padding: 0;

  ${media.md`
    align-items: ${({ isList, alignItems }) =>
      isList ? alignItems : 'center'};
    justify-content: ${({ align }) => align || 'flex-start'};
  `}

  ${space}
`;

const MenuLink = styled(Link)`
  text-transform: capitalize;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  position: relative;
  text-decoration: none;

  &.active:before {
    opacity: 1;
    background: #f66d1d;
  }

  :hover:before {
    opacity: 0.3;
  }

  :before {
    position: absolute;
    top: -4px;
    right: -7px;
    width: 6px;
    height: 6px;
    content: '';
    transition-timing-function: ease;
    transition-duration: 0.3s;
    transition-property: background-color, opacity;
    border-radius: 100%;
    opacity: 0;
    background: #f7f9f9;
  }

  :last-child {
    margin-right: 0px;
  }
`;

const SubMenu = styled(motion.div)`
  position: absolute;
  margin-top: 20px;
  top: 100%;
  left: 0;
  transform: translateX(-10%);
  background: rgba(26, 26, 26, 0.7);
  padding: 50px 50px;
  border-radius: 12px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
  z-index: 1000;
  width: auto;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: repeat(3, auto);
  gap: 10px 80px;

  &:before {
    content: '';
    position: absolute;
    top: -8px;
    left: 5%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid rgba(26, 26, 26, 0.7);
  }
`;

const List = styled.li`
  margin-right: ${({ isList }) => (isList ? '0' : '30px')};
  margin-bottom: ${({ isList }) => (isList ? '16px' : '0')};
  list-style: none;
  position: relative;

  &:hover ${SubMenu} {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }

  ${space}
`;
const SubMenuItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
  white-space: nowrap;

  a {
    color: #ffffff;
    text-decoration: none;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    display: block;
    padding: 5px 0;
    transition: color 0.3s ease-in-out;

    &:hover {
      color: #f66d1d;
    }
  }

  svg {
    margin-right: 10px;
  }
`;
const SubMenuLink = styled(Link)`
  text-transform: capitalize;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  position: relative;
  text-decoration: none;
`;

export default function Nav({
  menus = [],
  align,
  alignItems = 'flex-start',
  list,
  itemspace = {},
  ...rest
}) {
  const [openIndex, setOpenIndex] = useState(null);

  const handleMouseEnter = index => {
    setOpenIndex(index);
  };

  const handleMouseLeave = () => {
    setOpenIndex(null);
  };

  return (
    <Menu
      align={align}
      isList={list}
      {...rest}
      alignItems={alignItems}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3 }}
    >
      {menus.map((menu, index) => (
        <List
          isList={list}
          key={index}
          onMouseEnter={() => handleMouseEnter(index)}
          onMouseLeave={handleMouseLeave}
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{
            opacity: openIndex === index ? 1 : 0.5,
            scale: openIndex === index ? 1 : 0.9,
          }}
          transition={{ duration: 0.3 }}
          {...itemspace}
        >
          <MenuLink activeClassName="active" to={menu.url}>
            {menu.label}
          </MenuLink>
          {menu.submenu && (
            <SubMenu
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{
                opacity: openIndex === index ? 1 : 0,
                scale: openIndex === index ? 1 : 0.9,
              }}
              transition={{ duration: 0.3 }}
            >
              {menu.submenu.map((submenu, subIndex) => (
                <SubMenuItem key={subIndex}>
                  <SubMenuLink to={submenu.url}>{submenu.label}</SubMenuLink>
                </SubMenuItem>
              ))}
            </SubMenu>
          )}
        </List>
      ))}
    </Menu>
  );
}
