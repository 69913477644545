import React from "react";
import imgBg from "../images/image-bg.png";
import styled, { css } from "styled-components";
import { Image } from "./";
import { layout, space } from "styled-system";

const FigureWrapper = styled.figure`
  background-image: url(${imgBg});
  display: flex;
  align-items: center;
  position: relative;
  padding: 20px 28px 24px 23px;
  margin: 0;
  height: auto !important;

  img {
    display: block;
    margin: 0 auto;
    width: ${({ imgWidth }) => imgWidth || "100%"};
    height: auto;
    position: relative;
    z-index: 20;
    background: #020A14;
    padding: 40px;
    height: ${({ height }) => height}
  }

  span {
    position: absolute;
    width: 70%;
    height: 60%;

    bottom: 0;
    z-index: 10;
    background: ${({ barColor }) => barColor};
    display: block;

    ${({ barPosition }) =>
      barPosition === "right"
        ? css`
            right: 0;
          `
        : css`
            left: 0;
          `}
  }

    ${({ position }) =>
      position === "right"
        ? css`
            margin-left: auto;
          `
        : css`
            margin-right: auto;
          `}
  }

  ${layout};
  ${space};
`;

export default function Figure({
  src,
  alt,
  barColor,
  barPosition = "right",
  position,
  imgWidth,
  height = "400px",
  ...rest
}) {
  return (
    <FigureWrapper
      barColor={barColor}
      barPosition={barPosition}
      position={position}
      height={height}
      imgWidth={imgWidth}
      {...rest}
    >
      <Image loading="lazy" src={src} alt={alt} />
      <span />
    </FigureWrapper>
  );
}
