import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const BackToTopButton = styled.button`
  position: fixed;
  bottom: 40px;
  right: 40px;
  background-color: #f66d1d;
  color: #ffffff;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 30px;
  font-weight: bold;
  cursor: pointer;
  z-index: 1000;
  opacity: ${props => (props.visible ? '1' : '0')};
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out, background-color 0.3s ease-in-out;

  &:hover {
    background-color: #d55b17;
  }
`;

const BackToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);

    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return (
    <BackToTopButton onClick={scrollToTop} visible={isVisible}>
      ↑
    </BackToTopButton>
  );
};

export default BackToTop;
