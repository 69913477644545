import React from 'react';
import withSVG from './withSVG';

const ArrowRight = () => (
  <path
    stroke="#008FD4"
    strokeLinecap="round"
    strokeLinejoin="round"
    d="M9 11.348l6-5.378L9 .667M1 5.97h10"
  />
);

export default withSVG(ArrowRight, {
  viewBox: '0 0 16 12',
  width: '16',
  height: '12',
});
