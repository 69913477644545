import React from 'react';
import withSVG from './withSVG';

const CloseIcon = () => (
  <path
    d="M2.063 2l19.33 19.456M2 21.393l19.456-19.33"
    stroke="#F6931D"
    strokeWidth={3}
    strokeLinecap="round"
    strokeLinejoin="round"
  />
);

export default withSVG(CloseIcon, {
  viewBox: '0 0 23 23',
  width: '23',
  height: '23',
});
