import styled from 'styled-components';
import { Link } from 'gatsby';
import {
  space,
  color,
  layout,
  display,
  border,
  typography,
} from 'styled-system';

const LinkExtended = styled(Link)`
  color: #ffffff;
  text-decoration: none;

  ${space}
  ${color}
  ${layout}
  ${display}
  ${border}
  ${typography}
`;

export default LinkExtended;
