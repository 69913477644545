import React from 'react';
import { space, color, layout, display, flexbox } from 'styled-system';
import styled from 'styled-components';

const SVG = styled.svg`
  ${space}
  ${color}
  ${layout};
  ${display};
  ${flexbox};
`;

function withSVG(WrappedComponent, config = {}) {
  return class extends React.Component {
    render() {
      const {
        style,
        fill,
        stroke,
        width,
        height,
        viewBox,
        className,
        ...rest
      } = this.props;
      const customStyle = style || config.style || {};
      const customFill = fill || config.fill || '';
      const customStroke = stroke || config.stroke || '';
      const customWidth = `${width || config.width || '24'}px`;
      const customHeight = `${height || config.height || '24'}px`;
      const customClassName = className || config.className || '';
      const customViewBox = viewBox || config.viewBox || '0 0 24 24';

      return (
        <SVG
          width={customWidth}
          height={customHeight}
          style={customStyle}
          viewBox={customViewBox}
          fill={customFill}
          stroke={customStroke}
          className={`svg-icon ${customClassName || ''}`}
          xmlns="http://www.w3.org/2000/svg"
          {...rest}
        >
          <WrappedComponent stroke={customStroke} fill={customFill} />
        </SVG>
      );
    }
  };
}

export default withSVG;
