import React from "react";
import withSVG from "./withSVG";

const Job = () => (
  <g>
    <path
      fillRule="evenodd"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="0.75"
      d="M.375 21.478h24.57V5.284H.375v16.194z"
      clipRule="evenodd"
    ></path>
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="0.75"
      d="M5.878 5.283V.375h12.798v4.908M13.508 11.15c3.347-.309 7.466-1.896 11.438-5.867M.375 5.283s4.524 5.14 10.97 5.867"
    ></path>
    <path
      fillRule="evenodd"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="0.75"
      d="M12.637 12.835h-.263a.87.87 0 01-.87-.87v-1.318c0-.482.39-.872.87-.872h.263c.481 0 .871.39.871.872v1.318c0 .48-.39.87-.87.87z"
      clipRule="evenodd"
    ></path>
  </g>
);

export default withSVG(Job, {
  viewBox: "0 0 26 22",
});
