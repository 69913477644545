import { space, layout, typography } from "styled-system";
import styled, { css } from "styled-components";

const InputField = styled.input`
  width: 100%;
  border: 0;
  margin: 0;
  display: block;
  animation-duration: 10ms;
  border: 1px solid #152539;
  background: transparent;
  padding: 16px 8px;
  margin-bottom: 16px;
  font-size: 14px;
  color: #ffffff;
  border: 1px solid rgb(21, 37, 57);
  outline: 0;
  border-radius: 4px;

  :focus {
    border-color: var(--primary-color);
  }

  :hover {
    border-color: var(--light-color);
  }

  ::placeholder {
    font-size: 12px;
  }

  ${({ error }) =>
    error &&
    css`
      input {
        color: #d64541;
      }
    `}

  ${space}
  ${layout}
  ${typography}
`;

export default InputField;
