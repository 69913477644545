import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { Typography, Image, Box, Grid, DetailsLink } from '.';

const ImageWrapper = styled(Image)`
  box-shadow: ${({ type }) =>
    type === 'large'
      ? '-25px -25px 0px 0px var(--secondary-color)'
      : '-15px -15px 0px 0px var(--secondary-color)'};

  margin-left: ${({ type }) => (type === 'large' ? 'auto' : 0)};
`;

export default function CaseStudy({
  caseStudy,
  type = 'small',
  isLink = true,
}) {
  return (
    <Grid
      gridTemplateColumns={{
        _: '1fr',
        sm: `5fr 2fr`,
      }}
    >
      <Box display="flex" alignItems="center" mb={{ _: 2, sm: 0 }}>
        {isLink ? (
          <DetailsLink
            to={`/case-studies/${caseStudy.slug}`}
            variant="underline"
            textVariant={type === 'large' ? 'heading2' : 'heading4'}
            showStartArrow={false}
          >
            {caseStudy.title}
          </DetailsLink>
        ) : (
          <Typography
            color="#fff"
            variant={type === 'large' ? 'heading2' : 'heading4'}
          >
            {caseStudy.title}
          </Typography>
        )}
      </Box>
      <Box textAlign="right">
        <Link to={`/case-studies/${caseStudy.slug}`}>
          <ImageWrapper
            type={type}
            src={caseStudy.featuredImage.node.mediaItemUrl}
            alt={caseStudy.title}
          />
        </Link>
      </Box>
    </Grid>
  );
}
