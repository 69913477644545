import styled from 'styled-components';
import {
  grid,
  size,
  space,
  layout,
  typography,
  flexbox,
  position,
  color,
} from 'styled-system';

const Grid = styled.div`
  display: grid;
  grid-gap: ${props => props.gap || '32px'};
  flex-direction: row;

  @media screen and (max-width: 768px) {
    grid-gap: ${props => props.gapSm || '32px'};
  }

  ${flexbox}
  ${grid}
  ${typography}
  ${size}
  ${space}
  ${layout}
  ${position}
  ${color}
`;

export default Grid;
