/* eslint-disable import/no-cycle */
import { format } from 'date-fns';
import { Link } from 'gatsby';
import React from 'react';
import { Box, Grid, PreLoader, Typography } from '.';
import { useGetJobs } from '../hooks/useFetch';
import { JobIcon, TimeIcon } from '../icons';

export default function Jobs() {
  const { isLoading, isError, data } = useGetJobs();

  return (
    <Box>
      <Typography variant="heading3" mb={6}>
        Interested to work with us?
      </Typography>

      <PreLoader loading={isLoading} minHeight="100px">
        {isError && (
          <Typography variant="paragraph1" mb={6}>
            Sorry, We are facing some problems, please try again later.
          </Typography>
        )}
        {!isError && !data?.data?.length && (
          <Typography variant="paragraph1" mb={6}>
            Sorry, No vacancy available right now.
          </Typography>
        )}

        {data?.data?.map(job => (
          <Link to={`/jobs/${job.id}`} key={job.id}>
            <Box borderBottom="1px solid #142944" pb={3} mb={4}>
              <Grid
                gridTemplateColumns={{ _: '1fr', sm: '1fr 1fr 1fr' }}
                alignItems="center"
              >
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Box border px={2} pt={1} pb={0} mr={2}>
                    <JobIcon />
                  </Box>

                  <Box>
                    <Typography color="textPrimary" variant="paragraph2" mb={2}>
                      {job?.title}
                    </Typography>
                    <Typography variant="subtitle1" color="light">
                      Number of Posts : {job?.numberOfPosition}
                    </Typography>
                    <Box display={{ _: 'block', sm: 'none' }}>
                      <Typography mt={1} variant="subtitle1" color="light">
                        {job?.jobType}
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                <Box>
                  {/* <Box
                    display="flex"
                    alignItems="center"
                    mb={2}
                    mt={{ _: 4, md: 0 }}
                  >
                    <TimeIcon />
                    <Typography color="textPrimary" ml={1} variant="paragraph2">
                      Posted on :{' '}
                      {format(new Date(job?.publishedDate), 'MMMM dd, yyyy')}
                    </Typography>
                  </Box> */}
                  <Box display="flex" alignItems="center" mb={{ _: 4, md: 0 }}>
                    <TimeIcon />
                    <Typography color="textPrimary" ml={1} variant="paragraph2">
                      Deadline :{' '}
                      {format(new Date(job?.deadLine), 'MMMM dd, yyyy')}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  alignItems={{ _: 'flex-start', md: 'center' }}
                  justifyContent="center"
                  flexDirection="column"
                >
                  <Typography color="textPrimary" variant="paragraph2" mb={2}>
                    View job details
                  </Typography>
                  <Box display={{ _: 'none', sm: 'block' }}>
                    <Typography color="light" variant="subtitle1">
                      {job?.jobType}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Box>
          </Link>
        ))}
      </PreLoader>
    </Box>
  );
}
