import React from 'react';
import withSVG from './withSVG';

const ArrowLeft = () => (
  <path
    stroke="#008FD4"
    strokeLinecap="round"
    strokeLinejoin="round"
    d="M7 .652L1 6.03l6 5.303M15 6.03H5"
  />
);

export default withSVG(ArrowLeft, {
  viewBox: '0 0 16 12',
  width: '16',
  height: '12',
});
