export const breakpointsRule = {
  _: 600,
  sm: 768,
  md: 1024,
  lg: 1280,
  xl: 1536,
};

const breakpoints = ['600px', '768px', '1024px', '1280px', '1536px'];

// aliases
breakpoints.xs = breakpoints[0];
breakpoints.sm = breakpoints[1];
breakpoints.md = breakpoints[2];
breakpoints.lg = breakpoints[3];
breakpoints.xl = breakpoints[4];

const theme = {
  space: [0, 8, 16, 24, 32, 40, 48, 56, 64, 72, 100],
  breakpoints,
};

export default theme;
