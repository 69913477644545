import React from 'react';
import { Typography, Box, Image, Button, RawHTML, Grid } from './';
import { ArrowRight, ArrowLeft } from '../icons';
import { graphql, useStaticQuery } from 'gatsby';

export default function Testimonials() {
  const data = useStaticQuery(graphql`
    query {
      wpgraphql {
        testimonials {
          nodes {
            title
            content
            testimonialId
            meta {
              designation
            }
            featuredImage {
              node {
                altText
                sourceUrl
              }
            }
          }
        }
      }
    }
  `);

  const testimonials = data?.wpgraphql?.testimonials?.nodes ?? [];

  return (
    <>
      {testimonials.map(testimonial => (
        <Grid
          gridTemplateColumns={{ _: '1fr', sm: '1fr 3fr' }}
          alignItems="center"
          key={testimonial.testimonialId}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <Image
              width="100px"
              borderRadius="50%"
              src={testimonial.featuredImage.node.sourceUrl}
              alt={testimonial.featuredImage.node.altText || testimonial.title}
            />
            <Typography variant="h4" mt={2} mb={1}>
              {testimonial.title}
            </Typography>
            <Typography variant="body2">
              {testimonial.meta.designation}
            </Typography>
          </Box>
          <Box>
            <RawHTML html={testimonial.content} />

            <Box display="flex" alignItems="center" mt={4}>
              <Button variant="outlined" color="secondary" type="icon">
                <ArrowLeft />
              </Button>
              <Typography mx={2} variant="body2">
                1 / 1
              </Typography>
              <Button variant="outlined" color="secondary" type="icon">
                <ArrowRight />
              </Button>
            </Box>
          </Box>
        </Grid>
      ))}
    </>
  );
}
