import { useState, useEffect } from "react";

export default function useToggle(isOnScreen) {
  const isSSR = typeof window === "undefined";
  const [showContactForm, setShowContactForm] = useState(false);

  useEffect(() => {
    if (isOnScreen && !showContactForm) {
      setShowContactForm(true);
    }
  }, [isOnScreen]);

  return showContactForm && !isSSR;
}
