import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  /* spartan-regular - latin */
  /*  
  @font-face {
    font-family: 'Spartan';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('/fonts/spartan-v3-latin/spartan-v3-latin-regular.eot'); /* IE9 Compat Modes */
    /*src: local(''),
        url('/fonts/spartan-v3-latin/spartan-v3-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 
        url('/fonts/spartan-v3-latin/spartan-v3-latin-regular.woff2') format('woff2'), /* Super Modern Browsers 
        url('/fonts/spartan-v3-latin/spartan-v3-latin-regular.woff') format('woff'), /* Modern Browsers 
        url('/fonts/spartan-v3-latin/spartan-v3-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS 
        url('/fonts/spartan-v3-latin/spartan-v3-latin-regular.svg#Spartan') format('svg'); /* Legacy iOS 
  }
  
  /* spartan-700 - latin */
  /*
  @font-face {
    font-family: 'Spartan';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('/fonts/spartan-v3-latin/spartan-v3-latin-700.eot'); /* IE9 Compat Modes 
    src: local(''),
        url('/fonts/spartan-v3-latin/spartan-v3-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 
        url('/fonts/spartan-v3-latin/spartan-v3-latin-700.woff2') format('woff2'), /* Super Modern Browsers 
        url('/fonts/spartan-v3-latin/spartan-v3-latin-700.woff') format('woff'), /* Modern Browsers *
        url('/fonts/spartan-v3-latin/spartan-v3-latin-700.ttf') format('truetype'), /* Safari, Android, iOS 
        url('/fonts/spartan-v3-latin/spartan-v3-latin-700.svg#Spartan') format('svg'); /* Legacy iOS 
  }
*/
  /* :root {
    --bg-color: #020A14;
    --text-primary-color: #ffffff;
    --text-secondary-color: #69798C;
    --primary-color: #f66d1d;
    --secondary-color: #008FD4;
    --tertiary-color: #EAF1F1;
    --error-color: #D64541;
    --light-color: #ffffff;
    --primary-font: 'Spartan', sans-serif;
    --normal-weight: 400;
    --semi-bold-weight: 600;
    --bold-weight: 700;
    --light-weight: 300;
    --high-index: 999;
    --mid-index: 499;
    --low-index: 99;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  html {
    font-family: sans-serif;
    font-size: 14px;
    line-height: 1.6;
    text-size-adjust: none;
    -webkit-text-size-adjust: none;
    -moz-text-size-adjust: none;
  }
  body {
    font-family: var(--primary-font);
    background-color: var(--bg-color);
    color: var(--text-primary-color);
    font-weight: var(--normal-weight);
  } */
  a {
    text-decoration: none;
    color: var(--primary-color);
  }
  img {
    max-width: 100%;
    height: auto;
    display: block;
  }

  .grecaptcha-badge { 
    visibility: hidden; 
  }
  
`;

export default GlobalStyle;
