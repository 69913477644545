import React from "react";
import { Box } from "../components";
import styled from "styled-components";

const HeaderBar = styled.div`
  background: ${({ bgColor }) => bgColor};
  padding: 5px 10px;
  display: inline;
  font-size: 13px;
  font-family: "Source Code Pro", monospace;
  font-weight: 500;
  color: #182029;
`;

export default function LinedHeader({
  heading = "",
  children,
  showRightBorder = false,
  color = "#fbb27e",
}) {
  return (
    <>
      <Box borderBottom={`1px solid ${color}`}>
        <HeaderBar bgColor={color}>{heading}</HeaderBar>
      </Box>
      <Box
        borderRight={showRightBorder ? "1px dashed #142944" : ""}
        pt={3}
        pb={5}
      >
        {children}
      </Box>
    </>
  );
}
