import React from 'react';
import styled, { css } from 'styled-components';
import { space, layout, border } from 'styled-system';

const Img = styled.img`
  max-width: 100%;
  display: block;

  ${props =>
    props.center &&
    css`
      margin: 0 auto;
    `};

  ${space};
  ${border};
  ${layout};
`;

const Image = ({ src, alt, center, ...rest }) => (
  <Img src={src} alt={alt} center={center} {...rest} />
);

export default Image;
